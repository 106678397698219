/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  TextInput,
  Create,
  SimpleForm,
  SelectInput,
  BooleanInput,
  SelectArrayInput,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { EventContext } from "../../dataprovider/eventProvider";

export default (props) => {
  const { currentEvent } = useContext(EventContext);
  const { udf, statusList: statuses, passes: passesList } = currentEvent;
  const { type } = props;
  let statusList = [];
  if (statuses) {
    statusList = statuses[type];
  }
  let fields = [];

  if (udf) {
    const additionalFields = Object.keys(udf).map((field) => {
      if (udf[field].type === "boolean") {
        return (
          <Grid item xs={6} key={`udf.${udf[field].name}`}>
            <BooleanInput
              source={`udf.${udf[field].name}`}
              label={udf[field].label}
              fullWidth
              helperText={false}
            />
          </Grid>
        );
      }
      if (udf[field].type === "select") {
        return (
          <Grid item xs={6} key={`udf.${udf[field].name}`}>
            <SelectInput
              source={`udf.${udf[field].name}`}
              label={udf[field].label}
              fullWidth
              choices={udf[field].options}
              helperText={false}
            />
          </Grid>
        );
      }
      return (
        <Grid item xs={6} key={`udf.${udf[field].name}`}>
          <TextInput
            source={`udf.${udf[field].name}`}
            label={udf[field].label}
            fullWidth
            helperText={false}
          />
        </Grid>
      );
    });
    fields = [additionalFields];
  }

  return (
    <>
      <Create {...props}>
        <SimpleForm warnWhenUnsavedChanges sx={{ maxWidth: 500 }}>
          <Grid container spacing={1}>
            <Grid item lg={6} xs={6}>
              <TextInput source="firstName" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextInput source="lastName" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextInput source="title" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextInput source="company" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextInput source="country" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextInput source="phone" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextInput source="email" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextInput source="promo" fullWidth helperText={false} />
            </Grid>

            <Grid item lg={12} xs={12} sx={{ mb: 2 }}>
              <SelectArrayInput
                source="passes"
                choices={passesList || []}
                fullWidth
                helperText="Select Passes (You can select multiple passes)"
              />
            </Grid>
            {[...fields]}
          </Grid>
          <Grid container spacing={1}>
            <Grid item lg={6} xs={6}>
              <SelectInput
                source="status"
                choices={statusList.filter((s) => !s.pass) || []}
                fullWidth
                isRequired
              />
            </Grid>
            <Grid item lg={6} xs={6}>
              <SelectInput
                source="type"
                label="Request Type"
                helperText="Select one of Register, Agenda, Sponsor"
                choices={[
                  { id: "REGISTER", name: "REGISTER" },
                  { id: "SPONSOR", name: "SPONSOR" },
                  { id: "AGENDA", name: "AGENDA" },
                ]}
                fullWidth
                isRequired
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              <SelectInput
                source="delegateType"
                helperText="Select One of Delegate, Sponsor, Organiser, Media"
                choices={[
                  { id: "DELEGATE", name: "DELEGATE" },
                  { id: "SPEAKER", name: "SPEAKER" },
                  { id: "SPONSOR", name: "SPONSOR" },
                  { id: "ORGANISER", name: "ORGANISER" },
                  { id: "MEDIA", name: "MEDIA" },
                ]}
                fullWidth
                isRequired
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={6}>
              <BooleanInput source="optIn" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={6} xs={6}>
              <BooleanInput source="privacyPolicy" fullWidth helperText={false} required />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </>
  );
};
