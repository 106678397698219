/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  useUpdate,
  useRefresh,
  useNotify,
  usePermissions,
  List,
  Datagrid,
  ImageField,
  FunctionField,
  downloadCSV,
} from "react-admin";
import Link from "@mui/material/Link";
import jsonExport from "jsonexport/dist";
import Tooltip from "@mui/material/Tooltip";
import copy from "copy-to-clipboard";
import { ListView } from "../../components/listView";

const exporter = (speakers) => {
  const speakersExport = speakers.map((speaker) => {
    const { name, title, company, country, email, phone } = speaker;
    return { name, title, company, country, email, phone };
  });
  jsonExport(
    speakersExport,
    {
      headers: ["name", "title", "company", "country", "email", "phone"], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "speakers"); // download as 'posts.csv` file
    }
  );
};

export default (props) => {
  const [update] = useUpdate();
  const refresh = useRefresh();
  const { permissions } = usePermissions();
  const speakerPerms = permissions?.speaker || {};

  const notify = useNotify;

  const updateFunc = (id, diff, prevData = {}) => {
    update(props.resource, id, diff, prevData, {
      onSuccess: () => {
        refresh();
      },
      onFailure: ({ err }) => {
        notify(err.message, "error");
      },
    });
  };

  const ImageFieldColumn = ({ data: record }) =>
    record.image ? (
      <img src={record.image} width="60px" alt={record.name} />
    ) : (
      <img
        src={`https://ui-avatars.com/api/?background=1a237e&color=fff&name=${record.name}`}
        width="60px"
        alt={record.name}
      />
    );
  const fields = [
    {
      source: "name",
      label: "Image",
      rowDrag: true,
      width: 150,
      // headerCheckboxSelection: true,
      // checkboxSelection: true,
      cellRendererFramework: ImageFieldColumn,
      cellStyle: () => ({ lineHeight: 0 }),
    },
    { source: "name" },
    { source: "title" },
    {
      source: "company",
      cellRendererFramework: ({ data: record }) => `${record.company}, ${record.country}`,
    },
    {
      source: "phone",
      cellRendererFramework: ({ data: record }) => (
        <>
          {record.phone && (
            <>
              <Tooltip title="Click to copy">
                <Link
                  component="button"
                  onClick={() => {
                    copy(record.phone);
                  }}
                  underline="none"
                >
                  {record.phone}
                </Link>
              </Tooltip>
              ,
            </>
          )}
          <Tooltip title="Click to copy">
            <Link
              component="button"
              onClick={() => {
                copy(record.email);
              }}
              underline="none"
            >
              {record.email}
            </Link>
          </Tooltip>
        </>
      ),
    },
    {
      source: "isHighlighted",
      label: "Highlight",
      width: 120,
      cellRenderer: (params) => (params.value ? "YES" : "NO"),
      cellStyle: (params) => (params.value ? { color: "green" } : { color: "red" }),
    },
    {
      source: "hideSpeaker",
      label: "isHidden",
      width: 120,
      cellRenderer: (params) => (params.value ? "YES" : "NO"),
      cellStyle: (params) => (params.value ? { color: "green" } : { color: "red" }),
    },
  ];

  if (!speakerPerms.view) {
    return null;
  }

  return (
    <>
      {speakerPerms.edit ? (
        <ListView
          {...props}
          sort={{ field: "priority", order: "ASC" }}
          perPage={999}
          update={updateFunc}
          fields={fields}
          hasCreate={speakerPerms.create}
          hasEdit={speakerPerms.edit}
          rowHeight={70}
          exporter={exporter}
        />
      ) : (
        <List
          hasCreate={false}
          hasDelete={false}
          sort={{ field: "priority", order: "ASC" }}
          exporter={exporter}
        >
          <Datagrid bulkActionButtons={false}>
            <ImageField
              source="image"
              label="Speaker"
              sx={{ "& img": { maxWidth: 60, maxHeight: 60, objectFit: "contain" } }}
            />
            <FunctionField
              label="Name"
              source="firstName"
              render={(record) => <b>{record.name}</b>}
              key="name"
            />
            <FunctionField
              label="Details"
              render={(record) => (
                <span>
                  {record.title},
                  <br />
                  <b>
                    {record.company},
                    <br />
                    {record.country}
                  </b>
                </span>
              )}
              key="Info"
            />
            <FunctionField
              label="Phone"
              source="phone"
              render={(record) =>
                record.phone ? (
                  <Tooltip title="Click to copy">
                    <Link
                      component="button"
                      onClick={() => {
                        copy(`+${record.phone}`);
                      }}
                      underline="none"
                    >
                      +{record.phone}
                    </Link>
                  </Tooltip>
                ) : null
              }
              key="phone"
            />
            <FunctionField
              label="Email"
              source="email"
              render={(record) =>
                record.email ? (
                  <Tooltip title="Click to copy">
                    <Link
                      component="button"
                      onClick={() => {
                        copy(record.email);
                      }}
                      underline="none"
                    >
                      {record.email}
                    </Link>
                  </Tooltip>
                ) : null
              }
              key="email"
            />
          </Datagrid>
        </List>
      )}
    </>
  );
};
