export const htmlTemplate = `
<!DOCTYPE html>
<html>
  <head>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap"
      rel="stylesheet"
    />
  </head>
  <body style="margin: 0">
    <script
      src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"
      integrity="sha512-CNgIRecGo7nphbeZ04Sc13ka07paqdeTu0WR1IM4kNcpmBAUSHSQX0FslNhTDadL4O5SAGapGt4FodqL8My0mA=="
      crossorigin="anonymous"
      referrerpolicy="no-referrer"
    ></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/qrious/4.0.2/qrious.min.js"></script>
    <div style="height: 73mm; width: 73mm; display: flex">
      <div
        style="
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0px 16px;
        "
      >
        <h1
          style="
            font-size: 7.7mm;
            font-family: Poppins, sans-serif;
            font-weight: 600;
            word-wrap: break-word;
            text-transform: uppercase;
            margin: 0px 0;
            line-height: 7mm;
          "
        >#DELEGATE_NAME#</h1>
        <h3
          style="
            font-size: 5mm;
            font-family: Poppins, sans-serif;
            font-weight: 400;
            word-wrap: break-word;
            margin: 10px 0;
            line-height: 5mm;
          "
          
        >#DELEGATE_COMPANY#</h3>
        <div style="display: flex; justify-content: space-between; padding-top: 8px">
          <img src="https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=#DELEGATE_URL#" alt="QR Code will appear here" style="
            padding: 2px;
            background: #fff;
            height: 120px;
            width: 120px;
          ">
        </div>
      </div>
    </div>
     <script>
        window.onload = function() {
            setTimeout(function () {
                window.print();
            }, 100);

            setTimeout(function () {
                window.close();
            }, 2000);
        };
    </script>
  </body>
</html>
`;
