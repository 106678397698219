/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Select from "@mui/material/Select";
import DialogTitle from "@mui/material/DialogTitle";
import { EventContext } from "../../dataprovider/eventProvider";

function incrementLocalStorageItem(key) {
  let value = parseInt(localStorage.getItem(key), 10) || 0;
  value += 1;
  localStorage.setItem(key, value.toString());
  return value; // Return the updated value
}

export default function PassSelection({ open, setOpen, onConfirm }) {
  const handleClose = () => {
    setOpen();
  };

  const handleConfirm = () => {
    incrementLocalStorageItem("dbVersion");
    onConfirm(details);
    setOpen();
  };
  const { currentEvent } = useContext(EventContext);
  const [details, setDetails] = useState({
    allowPrinting: false,
    useWebcam: false,
  });
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Select Pass Type for Scanning</DialogTitle>
        {currentEvent?.passes && (
          <DialogContent>
            <Select
              id="pass"
              value={details?.pass || ""}
              fullWidth
              margin="dense"
              variant="filled"
              label="Pass"
              onChange={(e) => setDetails({ ...details, pass: e.target.value })}
            >
              {currentEvent.passes?.map((p) => (
                <MenuItem value={p.id} key={p.id}>
                  {p.name}
                </MenuItem>
              ))}
            </Select>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={details.allowPrinting}
                  onChange={(e) => {
                    setDetails({ ...details, allowPrinting: e.target.checked });
                  }}
                />
              }
              label="Print Label on Scan"
              labelPlacement="end"
              sx={{ mt: 2 }}
            />

            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={details.useWebcam}
                  onChange={(e) => {
                    setDetails({ ...details, useWebcam: e.target.checked });
                  }}
                />
              }
              label="Use Webcam"
              labelPlacement="end"
              sx={{ mt: 2 }}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
