/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useContext, useEffect, useState } from "react";
import {
  DatagridConfigurable,
  List,
  BooleanField,
  EditButton,
  useListContext,
  TextInput,
  SelectInput,
  DateField,
  FunctionField,
  DeleteWithConfirmButton,
  useRecordContext,
  TextField,
  usePermissions,
  useUnselectAll,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  SelectColumnsButton,
  Pagination,
  BulkDeleteButton,
} from "react-admin";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import copy from "copy-to-clipboard";
import { EventContext } from "../../dataprovider/eventProvider";
import BulkUpdateButton from "./bulkUpdateButton";
import BulkConfirmButton from "./bulkConfirmationV2";
import ImportButton from "../../components/uploadHelpers/RegistrationsUpload";

const getTabIdFromFilters = (filters, statuses) => {
  const t = statuses.findIndex((s) => s.id === filters?.status && filters?.pass === s.pass);
  if (t !== -1) {
    return t;
  }
  return 1;
};

function TabbedDatagrid(props) {
  const listContext = useListContext();
  const { currentEvent } = useContext(EventContext);
  const { udf, statusList: statuses } = currentEvent;
  const { filterValues, setFilters } = listContext;
  const { type, resource } = props;
  let statusList = [];
  if (statuses) {
    statusList = statuses[type];
  }
  const [tabValue, setTabValue] = useState(getTabIdFromFilters(filterValues, statusList));
  const handleChange = (event, value) => {
    const newFilterValues = getFilterValues(value);
    setFilters({ ...filterValues, ...newFilterValues });
  };

  const getFilterValues = (tabId) => {
    const valueObj = statusList?.[tabId] || { id: "CONVERTED", name: "CONVERTED" };
    return {
      status: valueObj.id,
      pass: valueObj.pass,
    };
  };

  useEffect(() => {
    const t = getTabIdFromFilters(filterValues, statusList);
    setTabValue(t);
  }, [filterValues, statusList]);

  let fields = [
    <FunctionField
      label="Name"
      source="firstName"
      render={(record) => <b>{`${record.firstName} ${record.lastName}`}</b>}
      key="name"
    />,
    <TextField source="title" key="title" />,
    <FunctionField
      label="Details"
      render={(record) => (
        <span>
          <b>
            {record.company},
            <br />
            {record.country}
          </b>
        </span>
      )}
      key="Info"
    />,
    <FunctionField
      label="Phone"
      source="phone"
      render={(record) => (
        <Tooltip title="Click to copy">
          <Link
            component="button"
            onClick={() => {
              copy(`+${record.phone}`);
            }}
            underline="none"
          >
            +{record.phone}
          </Link>
        </Tooltip>
      )}
      key="phone"
    />,
    <FunctionField
      label="Email"
      source="email"
      render={(record) => (
        <Tooltip title="Click to copy">
          <Link
            component="button"
            onClick={() => {
              copy(record.email);
            }}
            underline="none"
          >
            {record.email}
          </Link>
        </Tooltip>
      )}
      key="email"
    />,
    <TextField source="delegateType" key="utmSource" emptyText="-" />,
    <TextField source="promo" key="promo" />,
    <BooleanField
      source="optIn"
      key="optIn"
      TrueIcon={() => <CheckCircleSharpIcon color="success" />}
      FalseIcon={() => <CancelSharpIcon color="warning" />}
    />,
    <BooleanField
      source="privacyPolicy"
      key="privacyPolicy"
      label="Data Consent"
      TrueIcon={() => <CheckCircleSharpIcon color="success" />}
      FalseIcon={() => <CancelSharpIcon color="warning" />}
    />,
    <BooleanField
      source="confirmationSent"
      key="confirmationSent"
      label="Mail Sent"
      TrueIcon={() => <CheckCircleSharpIcon color="success" />}
      FalseIcon={() => <CancelSharpIcon color="warning" />}
      looseValue
    />,
    <TextField source="utmSource" key="utmSource" label="Lead Source" emptyText="-" />,
    <FunctionField
      label="Passes"
      source="passes"
      render={(record) => record.passes?.map((c) => <Chip label={c} key={c} />) || "-"}
      key="passes"
    />,
    <DateField source="createdAt" key="createdAt" />,
    <DateField source="updatedAt" key="updatedAt" />,
  ];

  const additionalFields = udf
    ?.filter((u) => u.formType?.includes(type))
    .map((field) => {
      if (field.type === "boolean") {
        return (
          <BooleanField
            source={`udf.${field.name}`}
            key={`udf.${field.name}`}
            label={field.label}
          />
        );
      }
      return (
        <TextField source={`udf.${field.name}`} key={`udf.${field.name}`} label={field.label} />
      );
    });

  fields = [...fields, additionalFields];

  const DeleteButton = () => {
    const record = useRecordContext();
    return (
      <DeleteWithConfirmButton
        resource="registrations"
        confirmTitle={`Remove ${record.firstName} ${record.lastName}`}
        confirmContent="You will not be able to recover this delegate. Are you sure?"
      />
    );
  };

  const PostBulkActionButtons = (prop) => {
    const { status } = prop.filterValues;
    return (
      <div style={{ marginLeft: "25px" }}>
        {(type === "AGENDA" || type === "SPONSOR") && (
          <BulkUpdateButton
            resource={resource}
            selectedIds={prop.selectedIds}
            label="Move to Registrations and Confirm"
            message="Are you sure you want to move this lead to registrations and confirm"
            data={{
              type: "REGISTER",
              status: "CONVERTED",
            }}
          />
        )}
        {type === "REGISTER" && status === "LEAD" && (
          <BulkUpdateButton
            resource={resource}
            selectedIds={prop.selectedIds}
            label="Convert"
            message="Are you sure you want to Convert this lead"
            data={{
              status: "CONVERTED",
            }}
          />
        )}

        <BulkConfirmButton
          resource={resource}
          selectedIds={prop.selectedIds}
          label="Send Email"
          message="Are you sure you want to send the email to the selected Delegates"
          data={{}}
          {...prop}
        />

        {status === "LEAD" && <BulkDeleteButton />}
      </div>
    );
  };

  const DataGrid = () => (
    <DatagridConfigurable
      size="small"
      sx={{
        color: "success.main",
      }}
      bulkActionButtons={<PostBulkActionButtons />}
    >
      {[...fields]}
      <EditButton resource="registrations" label="Edit" key="Edit" />
      <DeleteButton label="Delete" key="Delete" />
    </DatagridConfigurable>
  );

  return (
    <>
      {type === "REGISTER" && (
        <Tabs value={tabValue} indicatorColor="primary" onChange={handleChange}>
          {statusList?.map((choice, i) => (
            <Tab key={i} label={choice.name} value={i} />
          ))}
        </Tabs>
      )}
      <Divider />
      <div>
        <DataGrid />
      </div>
    </>
  );
}

const RegistrationFilters = [
  <TextInput label="Search" source="search" alwaysOn key={0} />,
  <SelectInput
    label="Opt In"
    source="optIn"
    key={1}
    choices={[
      { id: 1, name: "YES" },
      { id: 0, name: "NO" },
    ]}
  />,
  <SelectInput
    label="Delegate Type"
    source="delegateType"
    sx={{ marginBottom: 0 }}
    alwaysOn
    key={1}
    choices={[
      { id: "DELEGATE", name: "DELEGATE" },
      { id: "SPEAKER", name: "SPEAKER" },
      { id: "SPONSOR", name: "SPONSOR" },
      { id: "ORGANISER", name: "ORGANISER" },
      { id: "MEDIA", name: "MEDIA" },
    ]}
  />,
];

export default function OrderList(props) {
  const { permissions } = usePermissions();
  const registrationPerms = permissions?.registrations || {};
  const { resource, type } = props;
  const unselectAll = useUnselectAll(resource);

  useEffect(() => {
    unselectAll();
  }, [resource]);

  if (!registrationPerms.view) {
    return null;
  }

  const ListActions = () => (
    <TopToolbar sx={{ justifyContent: "start" }}>
      <SelectColumnsButton />
      <FilterButton />
      <CreateButton />
      <ImportButton />
      <ExportButton />
    </TopToolbar>
  );
  return (
    <List
      {...props}
      sort={{ field: "updatedAt", order: "DESC" }}
      filterDefaultValues={{ status: type === "REGISTER" ? "CONVERTED" : undefined }}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50]} />}
      filters={RegistrationFilters}
      hasCreate={registrationPerms.create}
      actions={<ListActions />}
      sx={{
        "& .RaList-main form": {
          flex: "none",
        },
        "& .RaList-main .MuiToolbar-root": {
          justifyContent: "start",
        },
        "& .RaList-main .MuiTablePagination-spacer": {
          display: "none",
        },
      }}
    >
      <TabbedDatagrid {...props} />
    </List>
  );
}
